<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import { Token } from "untils/token.js"
document.addEventListener("DOMContentLoaded", () => {
  const html = document.querySelector("html");
  let fontSize = window.innerWidth / 3.75;
  // fontSize = fontSize > 50 ? 50 : fontSize
  html.style.fontSize = fontSize + "px";
});
export default {
  // mounted() {
  // 	loginExpire(){
  // 		const token=Token.getToken()
  // 		if(token.expire<)
  // 	}
  // }
};
</script>
<style lang="scss">
@import "./assets/scss/reset.scss";
</style>
<style>
html,
body {
  min-height: 100%;
}
#app {
  min-height: 100%;
  background-color: #f5f6fa;
}
</style>
