import { get, post } from "./request";
export const Login = (query) => get("phone_pwd_login", query); //登陆
export const Logins = (query) => get("phone_pwd_logins", query); //登陆

export const phoneLogin = (query) => get("phone_login", query); //登陆
export const phoneLogins = (query) => get("phone_logins", query); //登陆

export const phoneMetalin = (query) => get("phone_metalin", query); //入库
export const phoneCustbill = (query) => get("phone_custbill", query); //欠存
export const phoneMetalout = (query) => get("phone_metalout", query); //出库
export const phoneJbljxc = (query) => get("phone_jbljxc", query); //出库
//兴泓
export const custIndex = (query) => get("cust_index", query); //单据列表
export const custNewList = (query) => get("cust_new_list", query); //单据列表类型
export const custIndexDetails = (query) => get("cust_index_details", query); //单据详情
export const erpList = (query) => get("erp_list", query); //下拉
export const erpIndex = (query) => get("erp_index", query); //每日销售统计
export const erpSales = (query) => get("erp_sales", query); //销售汇总
export const erpYwySales = (query) => get("erp_ywy_sales", query); //业务员销售
export const erpBarprc = (query) => get("erp_barprc", query); //结价汇总
export const erpCust = (query) => get("erp_cust", query); //客户存欠汇总
export const phonePwdUpdate = (query) => get("phone_pwd_update", query); //修改密码
export const uploadImg = (query) => post("upload_img", query); //上传图片
export const custSaveImg = (query) => post("cust_save_img", query); //上传单据
export const phoneReportLogin = (query) => get("phone_report_login", query); //登录判断
export const phoneOutLogin = (query) => get("phone_out_login", query); //登录判断
export const phoneTokenRpt = (query) => get("phone_token_rpt", query); //表单权限判断
