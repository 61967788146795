import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/login.vue"),
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../pages/index.vue"),
  },
  {
    path: "/billDetail",
    name: "billDetail",
    component: () => import("../pages/billDetail.vue"),
  },
  {
    path: "/indexAdmin",
    name: "indexAdmin",
    component: () => import("../pages/indexAdmin.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../pages/search.vue"),
  },
  {
    path: "/daysale",
    name: "daysale",
    component: () => import("../pages/outSide/daysale.vue"),
  },
  {
    path: "/saleall",
    name: "saleall",
    component: () => import("../pages/outSide/saleall.vue"),
  },
  {
    path: "/servicesaleall",
    name: "servicesaleall",
    component: () => import("../pages/outSide/servicesaleall.vue"),
  },
  {
    path: "/priceall",
    name: "priceall",
    component: () => import("../pages/outSide/priceall.vue"),
  },
  {
    path: "/custbalance",
    name: "custbalance",
    component: () => import("../pages/outSide/custbalance.vue"),
  },
  {
    path: "/mine",
    name: "mine",
    component: () => import("../pages/mine.vue"),
  },
  {
    path: "/forget",
    name: "forget",
    component: () => import("../pages/forget.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
