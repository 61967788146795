import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import { phoneReportLogin } from "@/api/index";

import { Toast } from "vant";
Toast.setDefaultOptions("loading", { forbidClick: true });
Vue.use(Vant);
Vue.config.productionTip = false;
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
  console.log(token, from, to);
  if (to.path != "/login") {
    phoneReportLogin().then((res) => {
      console.log("验证登录", res);
      if (res.data.code != 2000) {
        setTimeout(() => {
          // localStorage.clear();
          next("/login");
        }, 1000);
        return;
      }
    });
  }
  if (!token && to.path != "/login") {
    if (
      (to.path == "/index" || to.path == "/indexAdmin") &&
      from.path == "/login"
    ) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
