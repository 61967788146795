import axios from "axios";
import baseURL from "./url.js";
import { Toast } from "vant";
import router from "@/router/index.js";

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  baseURL: baseURL,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    // 每次请求前添加 token
    const token = window.localStorage.getItem("token");
    if (config.data) config.data.token = token;
    else if (!config.params) {
      const params = { token: token };
      config.params = params;
    } else config.params.token = token;

    return config;
  },
  (error) => {
    console.log("error", error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    console.log(response);
    if (response.data.code != 2000) {
      if (!response.config.url.includes("rear_message_inform")) {
        if (response.data.data || response.data.msg) {
          if (!(response.data.data.info instanceof Array)) {
            //console.log(response.data.data.info);
            Toast.fail({
              message: response.data.data.info,
            });
            if (response.data.code == 40001) {
              localStorage.clear();
              router.replace("/login");
            }
          }
        }
      }
    }

    return response;
  },
  (error) => {
    console.log(error);
  }
);

export function get(url, query) {
  return service({
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    params: query,
  });
}

export function post(url, query) {
  return service({
    url: url,
    method: "post",
    data: query,
  });
}
export function formDatapost(url, query) {
  return service({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: query,
  });
}

export function post_system(url, query) {
  return service({
    url: url,
    method: "post",
    data: query,
  });
}

export default service;
